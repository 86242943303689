import React from 'react'
import { getMerchTileFromProductTile, getMerchTileFromMerchCategory } from './utils'
import { BoundedContent, Carousel, CarouselSlide, Column, GridContainer, Row, useIsScreenClassInitialized, useScreenClass } from '@vp/swan'
import { GenericTileSectionHeader } from './GenericTileSectionHeader'
import { GenericTile } from './GenericTile'
import { Category, IMerchandisingCategoryTile, IProductTile, ProductCategoriesProps, TileWrapperProps } from '../types'

export const ProductCategoriesContainer = ({ data }: ProductCategoriesProps) => {
  const isScreenInitialized = useIsScreenClassInitialized()
  const screenClass = useScreenClass()
  const screenToSlidesMap: Record<string, number> = {
    xs: 2.1,
    sm: 7,
    md: 6,
    lg: 9,
    xl: 10,
  }
  const elementID = 'productCategories'
  const SECTION = 'Category Section'
  const { pageData, useNewTiles } = data
  const TILE_TYPE_CAT = 'category'
  const catTiles = useNewTiles
    ? pageData.categories
      .map((tileData: TileWrapperProps, index: number) => {
        const tile = {
          ...tileData,
          useNewTiles,
          section: SECTION,
          position: index + 1,
          mpv: tileData?.template?.type === TILE_TYPE_CAT ? tileData.template.productsList?.selectedProduct.mpvId : {},
        }
        return tile
      })
      .filter((data: { template: { type: string } }) => data.template.type === TILE_TYPE_CAT)
    : (pageData.productCategories
        .filter(data => data != null)
        .map((data, index: number) => {
          const isMerchCategory = data.__typename === 'ContentfulMerchandisingCategory'
          if (isMerchCategory) {
            const merchCategory = data as IMerchandisingCategoryTile
            return getMerchTileFromMerchCategory(merchCategory, index + 1)
          } else {
            const productTile = data as IProductTile
            return getMerchTileFromProductTile(productTile, index + 1)
          }
        }) as Category[])
  return (
    <BoundedContent mt='0' mb='9'>
      <GridContainer>
        <GenericTileSectionHeader title={pageData.productCategoriesTitle} section={SECTION} />
        <Row>
          <Column span={12} pl={{ xs: '2', md: '4' }} pr={{ xs: '3', md: '4' }}>
            <Carousel
              slidesToShow={screenToSlidesMap}
              slidesToScroll={2}
              infinite={false}
              gridGutters
              progressIndicator='none'
              arrows
              accessibleTextForPrevious='something'
              accessibleTextForNext='something'
              arrowVerticalPosition={isScreenInitialized && screenClass === 'sm' ? '30%' : '35%'}
              id={elementID.replaceAll(' ', '')}
            >
              {catTiles.map((tile: Category) => (
                <CarouselSlide data-testid='slick-item' key={tile.contentful_id}>
                  <GenericTile
                    tileSkin='circular'
                    tile={tile}
                    imageLoadingStrategy='eager'
                    locale={data.locale}
                  />
                </CarouselSlide>
              ))}
            </Carousel>
          </Column>
        </Row>
      </GridContainer>
    </BoundedContent>
  )
}
